<template>
  <div class="bauevents">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('bauevents.title') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
          <div class="row">
            <va-input class="flex md2" v-model="newData.eventStart" :placeholder="$t('anmelden.eventStart')" type="date" />
            <va-input class="flex md2" v-model="newData.eventEnd" :placeholder="$t('anmelden.eventEnd')" type="date" />
            <va-input class="flex md6"  v-model="newData.eventDescr" :placeholder="$t('anmelden.eventWork')" />
            <div class="flex md2">
              <va-button small color="info" @click="saveEvent(newData)"> {{ $t('common.new') }}</va-button>
            </div>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal v-model="toDelete.showDeleteModal"
      :message="toDelete.msg"
      title="Einsatz löschen"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      @ok="deleteEvent()"/>
    <div class="row" v-for="(ev,evK) in workEventList" :key="evK">
      <div class="flex md12">
        <va-card>
          <va-accordion>
            <va-collapse>
              <div class="row" slot="header">
                <va-input v-model="ev.eventStart" class="flex md2" type="date" @input="ev.isDirty=true" />
                <va-input v-model="ev.eventEnd" class="flex md2" type="date" @input="ev.isDirty=true" />
                <va-input v-model="ev.eventDescr" class="flex md5" @input="ev.isDirty=true" />
                <div class="flex md1 flex-center">
                  {{ev.sumWorkersCount}}
                </div>
                <div class="flex md1">
                  <va-button small v-if="ev.isDirty" @click="saveEvent(ev)"> {{ $t('common.save') }}</va-button>
                </div>
                <div class="flex md1">
                  <va-button small @click="askDeleteEvent(ev)" color="danger"> {{ $t('common.delete') }}</va-button>
                </div>
              </div>
              <div class="row" slot="body">
                <div class="flex md2">
                  <va-button small @click="getSubscribers(ev, $event)" color="info"> {{ $t('common.update') }}</va-button>
                </div>
                <div class="va-table-responsive">
                  <table class="va-table" id="va-table-bauEvents">
                    <thead>
                      <tr>
                        <th>{{ $t('bauevents.table.name') }}</th>
                        <th>{{ $t('bauevents.table.email') }}</th>
                        <th>{{ $t('bauevents.table.count') }}</th>
                        <th>{{ $t('bauevents.table.comment') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="subs in ev.subscribers" :key="subs.userEventID">
                        <td>{{ subs.userName }}</td>
                        <td>{{ subs.userEmail }}</td>
                        <td style="text-align: center;">{{ subs.workersCount }}</td>
                        <td>{{ subs.comments }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </va-collapse>
          </va-accordion>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'bauevents',
  data () {
    this.getEvents()
    return {
      workEventList: {},
      newData: {
        eventID: -1,
        eventStart: '',
        eventEnd: '',
        eventDescr: '',
      },
      currState: {
        msgType: '',
        msg: '',
      },
      toDelete: {
        showDeleteModal: false,
        msg: '',
        eventID: -1,
      },
    }
  },
  methods: {
    getEvents () {
      this.$srvconn.sendMessage('getBauEvents')
        .then(response => {
          if (response.msgType === 'getBauEvents') {
            this.workEventList = response.userData.bauEvents
          }
          // console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveEvent (ev) {
      var usrData = {
        eventID: ev.eventID,
        eventStart: ev.eventStart,
        eventEnd: ev.eventEnd,
        eventDescr: ev.eventDescr,
      }
      this.$srvconn.sendMessage('saveBauEvent', usrData)
        .then(response => {
          this.currState.msg = response.userData
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
          } else {
            this.currState.msgType = 'success'
            ev.isDirty = false
            if (ev.eventID === -1) {
              ev.eventStart = ''
              ev.eventEnd = ''
              ev.eventDescr = ''
              this.getEvents()
            }
          }
          // console.log(response)
        })
        .catch(err => {
          console.log('ERROR: ' + err)
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
    askDeleteEvent (ev) {
      this.toDelete.msg = this.$t('bauevents.delMsg1') + ev.eventDescr +
                          this.$t('bauevents.delMsg2') +
                          this.$t('bauevents.delMsg3') + ev.sumWorkersCount +
                          this.$t('bauevents.delMsg4')
      this.toDelete.eventID = ev.eventID
      this.toDelete.showDeleteModal = true
    },
    deleteEvent () {
      if (this.toDelete.eventID > 0) {
        var usrData = {
          eventID: this.toDelete.eventID,
        }
        this.$srvconn.sendMessage('deleteBauEvent', usrData)
          .then(response => {
            this.currState.msg = response.userData
            if (response.msgType === 'error') {
              this.currState.msgType = 'error'
            } else {
              this.currState.msgType = 'success'
              this.toDelete.eventID = -1
              this.toDelete.msg = ''
              this.getEvents()
            }
          })
          .catch(err => {
            console.log('ERROR: ' + err)
            this.currState.msgType = 'error'
            this.currState.msg = err
            this.toDelete.eventID = -1
            this.toDelete.msg = ''
          })
      }
    },
    getSubscribers (ev, evObj) {
      ev.subscribers = []
      var usrData = {
        eventID: ev.eventID,
      }
      this.$srvconn.sendMessage('getSubscribers', usrData)
        .then(response => {
          if (response.msgType === 'getSubscribers') {
            ev.subscribers = response.userData.subscribers
            if (evObj.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.__vue__) {
              evObj.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.__vue__.collapse()
              evObj.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.__vue__.expand()
            }
          }
        })
        .catch(err => {
          console.log('ERROR: ' + err)
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
  },
}
</script>

<style>
  #va-table-bauEvents td {
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid #dddddd;
  }

  #va-table-bauEvents tr:nth-child(even) {
    background-color: #f2f2f2;
  }
</style>
